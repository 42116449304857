import { FC } from 'react';
import ReportIcon from '~/icons/ReportIcon';
import { Article } from '~/types';

import listStyles from './ArticleListCard.module.scss';

interface Props {
  article: Article;
}

const AdminArticleInfo: FC<Props> = ({ article }) => {
  return (
    <>
      {article.did_image_failed_test && (
        <p className={listStyles.imageError}>
          <ReportIcon />
          Image had failed the test
        </p>
      )}
      {article.image_explanation && (
        <p className={listStyles.imageError}>
          <ReportIcon />
          Explanation: {article.image_explanation}
        </p>
      )}
      {article.is_reference_image_used && (
        <p className={listStyles.imageError}>
          <ReportIcon />
          Failed to get people images. A placeholder image was used instead.
        </p>
      )}
      {!!article.top_tags_to_covers && (
        <div className={listStyles.topTags}>
          <span className={listStyles.topTagsHeading}>
            Categories to Covers:
          </span>
          {article.top_tags_to_covers.map((topTag, index) => (
            <div className={listStyles.topTag} key={topTag.id}>
              <span key={topTag.id}>{topTag.title}</span>
              {index < article.top_tags_to_covers.length - 1 && <span>+</span>}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default AdminArticleInfo;
