import { FC } from 'react';
import ArticleListHighlights from '~/components/Article/ArticleListHighlights/ArticleListHighlights';
import ResponsiveImage from '~/components/ResponsiveImage/ResponsiveImage';
import { useUser } from '~/context/UserContext';
import { Article } from '~/types';

import { Link } from '@remix-run/react';

import ArticleActions from '../ArticleActions/ArticleActions';
import ArticleDate from '../ArticleAdditional/ArticleDate/ArticleDate';
import ReportBlock from '../ReportBlock/ReportBlock';
import TagStats from '../TagStats/TagStats';
import AdminArticleInfo from './AdminArticleInfo';
import listStyles from './ArticleListCard.module.scss';

interface Props {
  article: Article;
}

const ArticleListCard: FC<Props> = ({ article }) => {
  const { user } = useUser();

  return (
    <div className={listStyles.card}>
      <article className={listStyles.cardArticle}>
        <div className={listStyles.imageHolder}>
          <Link to={`/article/${article.slug}`} aria-label={article.title}>
            <ResponsiveImage
              mediaData={article.media}
              alt={article.title}
              className={listStyles.img}
            />
          </Link>
          {article.media?.thumbnail &&
          article.media?.thumbnail.is_ai_generated ? (
            <ReportBlock article_id={article.id} />
          ) : (
            <>
              {article.media?.thumbnail?.credits && (
                <div
                  className={listStyles.creditLink}
                  dangerouslySetInnerHTML={{
                    __html: article.media?.thumbnail?.credits,
                  }}
                ></div>
              )}
            </>
          )}
        </div>
        <div className={listStyles.cardBottom}>
          {!!user?.is_admin && <AdminArticleInfo article={article} />}
          <Link className={listStyles.link} to={`/article/${article.slug}`}>
            <h2 className={listStyles.heading}>{article.title}</h2>
          </Link>
          <ArticleDate publishedAt={article.published_at} />
          {article.highlights && article.highlights.length > 0 && (
            <div className={listStyles.highliths}>
              <ArticleListHighlights list={article.highlights} />
            </div>
          )}
          {!!article.tag_stats && user?.is_admin && (
            <TagStats
              stats={article.tag_stats}
              version={article.auto_generation_version}
            />
          )}
          {user?.is_admin && (
            <a
              className={listStyles.adminLink}
              target='blank'
              href={`https://api.bp.news/admin/articles/${article.id}/edit`}
            >
              Admin
            </a>
          )}
          <div className={listStyles.actionsWrap}>
            <ArticleActions
              articleId={article.id}
              votesMeta={article.votes_meta}
              commentsCount={article.comments.length ?? 0}
              slug={article.slug}
              tagStats={article.tag_stats}
            />
          </div>
        </div>
      </article>
    </div>
  );
};

export default ArticleListCard;
